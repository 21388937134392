import React from "react";
import css from "./buyAppButtons.module.scss";
import googlePlayIcon from "../../../images/common/Google_Play_icon.svg";
import appStoreIcon from "../../../images/common/App_Store_icon.svg";
import {graphql, useStaticQuery} from 'gatsby';

const query = graphql`
    query {
        site {
          siteMetadata {
            appStoreMainUrl
            appStoreTimberIdUrl
            appStoreDcodeRUrl
            googlePlayUrl
            appStoreEnabled
            googlePlayEnabled
          }
        }
    }
`;

export const buyAppButtonsVersions = {
    standard: 'standard',
    footer: 'footer'
}

export const urlTypes = {
    timberId: 'timberId',
    dcodeR: 'dcodeR',
    main: 'main'
}

const isAnyEnabled = site => {
    const {appStoreEnabled, googlePlayEnabled} = site.siteMetadata;
    return googlePlayEnabled || appStoreEnabled;
}

export const BuyAppButtons = props => {
    const {urlType = urlTypes.main, version = buyAppButtonsVersions.standard} = props;
    const {site} = useStaticQuery(query);
    if (!isAnyEnabled(site)) return null;
    const {
        appStoreMainUrl,
        appStoreTimberIdUrl,
        appStoreDcodeRUrl,
        googlePlayUrl,
        appStoreEnabled,
        googlePlayEnabled
    } = site.siteMetadata;

    let appStoreUrl;
    switch (urlType) {
        case urlTypes.timberId:
            appStoreUrl = appStoreTimberIdUrl;
            break;
        case urlTypes.dcodeR:
            appStoreUrl = appStoreDcodeRUrl;
            break;
        case urlTypes.main:
            appStoreUrl = appStoreMainUrl;
            break;
        default:
            appStoreUrl = appStoreMainUrl;
            break;
    }

    const versionClass = css[`${version}Wrapper`];
    return (
        <div className={[css.wrapper, versionClass].join(' ')}>
            {appStoreEnabled &&
                <a href={appStoreUrl} title="AppStore" target="_blank">
                    <img src={appStoreIcon} alt=""/>
                </a>
            }
            {googlePlayEnabled &&
                <a href={googlePlayUrl} title="GooglePlay" target="_blank">
                    <img src={googlePlayIcon} alt=""/>
                </a>
            }
        </div>
    )
}

export default BuyAppButtons;